<template>
  <li role="presentation">
    <hr class="flyout-divider" role="separator" aria-orientation="horizontal" v-bind="$attrs"/>
  </li>
</template>

<script>
export default {
  name: 'FlyoutDivider',
  inheritAttrs: false
}
</script>

<style lang="scss">
$flyout-divider-gap: $dropdown-divider-margin-y !default;
$flyout-divider-color: $dropdown-divider-bg !default;
$flyout-divider-size: $hr-border-width !default;

.flyout-divider {
  margin-top: $flyout-divider-gap;
  margin-bottom: $flyout-divider-gap;
  background-color: $flyout-divider-color;
  border: 0 none;
  height: $flyout-divider-size;
}
</style>
