<template>
  <li :class="['flyout-item', { 'active': active }]" role="presentation">
    <component :is="tag" class="flyout-item-link" role="menuitem" v-bind="$attrs"><slot/></component>
    <div class="flyout-item-action">
      <slot name="editaction"></slot>
    </div>
  </li>
</template>

<script>
export default {
  name: 'FlyOutItem',
  inheritAttrs: false,
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tag () {
      return Object.keys(this.$attrs).includes('href') ? 'a' : 'span'
    }
  }
}
</script>

<style lang="scss">
$flyout-item-padding-y: $dropdown-item-padding-y !default;
$flyout-item-padding-x: $dropdown-item-padding-x !default;
$flyout-item-color: $dropdown-link-color !default;
$flyout-item-action-border: $border-width solid $dropdown-divider-bg !default;

$flyout-item-hover-bg: $dropdown-link-hover-bg !default;
$flyout-item-hover-color: $dropdown-link-hover-color !default;

$flyout-item-active-bg: $dropdown-link-active-bg !default;
$flyout-item-active-color: $dropdown-link-active-color !default;

.flyout-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .flyout-item-link,
  .flyout-item-action > * {
    display: block;
    padding: $flyout-item-padding-y $flyout-item-padding-x;
    color: $flyout-item-color;

    &:hover {
      background-color: $flyout-item-hover-bg;
      color: $flyout-item-hover-color;
    }
  }

  .flyout-item-link {
    flex: 0 1 100%;
  }

  .flyout-item-action {
    border-left: $flyout-item-action-border;
  }

  &.active {
    .flyout-item-link,
    .flyout-item-action > * {
      background-color: $flyout-item-active-bg;
      color: $flyout-item-active-color;
    }
  }
}
</style>
