<template>
  <div :class="['flyout', { 'is-showing': state, 'custom-flyout': custom }]" @click.stop>
    <b-link class="flyout-button" @click.stop="toggle"><slot name="button"/> <b-icon class="button-icon" icon="arrow-dropdown"/></b-link>

    <transition name="flyout">
      <ul class="flyout-menu" v-if="state">
        <slot/>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Flyout',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    custom: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      state: this.$props.show,
      others: false
    }
  },
  methods: {
    toggle () {
      if (!this.disabled) {
        this.closeOthers()
        this.state = !this.state
      }
    },
    close () {
      if (!this.disabled) {
        if (!this.others) this.state = false
      }
    },
    closeOthers () {
      if (!this.disabled) {
        this.others = true
        document.dispatchEvent(new Event('click'))
        this.others = false
      }
    }
  },
  created () {
    document.addEventListener('click', this.close, false)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.close, false)
  },
  watch: {
    '$props.show' (show) {
      this.state = show
    }
  }
}
</script>

<style lang="scss">
$flyout-menu-zindex: $zindex-dropdown !default;
$flyout-menu-margin: 0 !default;
$flyout-menu-padding: $dropdown-padding-y $dropdown-padding-x !default;
$flyout-menu-min-width: $dropdown-min-width !default;
$flyout-menu-bg: $dropdown-bg !default;
$flyout-menu-box-shadow: $dropdown-box-shadow !default;
$flyout-menu-font-weight: inherit !default;
$flyout-menu-font-size: $dropdown-font-size !default;

.flyout {
  .flyout-button {
    text-decoration: none;

    .button-icon {}
  }

  .flyout-menu {
    margin: 0;
    @include list-unstyled();
    z-index: $flyout-menu-zindex;
  }

  &.is-showing {
    .flyout-button {
      .button-icon {
        transform: scaleY(-1);
      }
    }
  }

  &:not(.custom-flyout) {
    position: relative;

    .flyout-menu {
      @include list-unstyled();
      position: absolute;
      top: 100%;
      left: 50%;
      z-index: $flyout-menu-zindex;
      margin: $flyout-menu-margin;
      padding: $flyout-menu-padding;
      min-width: $flyout-menu-min-width;
      background-color: $flyout-menu-bg;
      box-shadow: $flyout-menu-box-shadow;
      transform: translate(-50%, $caret-width);
      font-weight: $flyout-menu-font-weight;
      font-size: $flyout-menu-font-size;

      &:before {
        display: block;
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-width: 0 $caret-width $caret-width;
        border-style: solid;
        border-color: transparent transparent $flyout-menu-bg;
      }
    }
  }
}
</style>
